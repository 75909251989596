import React from 'react';
import { Container } from 'react-bootstrap';

const SlideBanner = () => {
  return (
    <Container fluid className="p-0">
      <div className="slide-banner">
        <p className="slide-banner-text">
          $BURGUM 🇺🇸 $BURGUM 🇺🇸 $BURGUM 🇺🇸 $BURGUM 🇺🇸
          $BURGUM 🇺🇸 $BURGUM 🇺🇸 $BURGUM 🇺🇸 $BURGUM 🇺🇸
          $BURGUM 🇺🇸 $BURGUM
        </p>
      </div>
    </Container>
  );
};

export default SlideBanner;