import React, { useState } from 'react';
import { Button, Offcanvas, Container, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

const HowToBuy = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const contractAddress = "A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    alert('Contract address copied to clipboard!');
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.children}
    </Tooltip>
  );

  return (
    <>
      <div className="buy-container py-3" style={{ backgroundColor: '#162975', color:'white'}}>
      <Container fluid className="h-100">
          <Row className="align-items-center h-100">
            <Col lg={6} className="text-center mb-4 mb-lg-0">
              <h1 className="text-white mt-5 mb-4">How to Buy $BURGUM?</h1>
              <Button variant="primary" onClick={handleShow} className="learn-btn mb-3">
                Click here.
              </Button>
              <div className="mt-4 mb-4">
                <Row className="justify-content-center align-items-center">
                  <Col xs="auto">
                    <h5 className="text-white mb-0">Contract Address:</h5>
                  </Col>
                  <Col xs="auto">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{contractAddress}</Tooltip>}
                    >
                      <p className="contract-address mb-0">
                        {contractAddress.slice(0, 6)}...{contractAddress.slice(-4)} {' '}
                      </p>
                    </OverlayTrigger>
                  </Col>
                  <Col xs="auto">
                    <Button variant="light" onClick={handleCopy} aria-label="Copy contract address">
                      Click to copy 💾
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center mt-3">
                  <Col xs="auto">
                    <h5 className="text-white mb-0">Links:</h5>
                  </Col>
                  <Col xs="auto">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>https://dexscreener.com/solana/bnpcjqdz93kqxphmvtz2btmkhacrrwekudxqw3urk7cj</Tooltip>}
                    >
                      <a href="https://dexscreener.com/solana/bnpcjqdz93kqxphmvtz2btmkhacrrwekudxqw3urk7cj" className="link-light mx-2" target="_blank" rel="noopener noreferrer">
                        Dexscreener
                      </a>
                    </OverlayTrigger>
                  </Col>
                  <Col xs="auto">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>https://www.dextools.io/app/en/solana/pair-explorer/BNpcJQDz93KqXpHMVtz2BTMKhAcRrwekudxQW3UrK7cJ</Tooltip>}
                    >
                      <a href="https://www.dextools.io/app/en/solana/pair-explorer/BNpcJQDz93KqXpHMVtz2BTMKhAcRrwekudxQW3UrK7cJ" className="link-light mx-2" target="_blank" rel="noopener noreferrer">
                        Dextools
                      </a>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="text-center">
              <img src="/images/dawg.jpg" alt="Burgum Token" className="img-fluid rounded shadow" />
            </Col>
          </Row>
        </Container>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="start" scroll={true} backdrop={true} className="w-100 vh-100">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-center w-100">
            <h1>How to buy</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container className="text-center">
            <h2 className="mb-4 text-title">Step-by-Step Guide</h2>
            <div className="mb-4 text-content">
              <h3>Create a Wallet</h3>
              <p>Download Phantom or your wallet of choice from the app store, install it on your device, and securely create your wallet.</p>
            </div>
            <div className="mb-4 text-content">
              <h3>Get Some SOL</h3>
              <p>Ensure you have SOL in your wallet to be able to purchase $BURGUM. SOL can be bought through various exchanges.</p>
            </div>
            <div className="mb-4 text-content">
              <h3>Go to Jupiter</h3>
              <p>Access the Jupiter exchange through your wallet or its web platform, and prepare to exchange your SOL for $BURGUM.</p>
            </div>
            <div className="mb-4 text-content">
              <h3>Swap for BURGUM</h3>
              <p>On Jupiter, select $BURGUM from the list of available tokens, and swap your SOL for $BURGUM seamlessly.</p>
            </div>

            <Row className="justify-content-center">
              <Col xs="auto">
                <Button
                  onClick={() => window.open("https://raydium.io/swap/?inputCurrency=sol&outputMint=A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC&fixed=in&inputMint=sol", "_blank")}
                  style={{ fontSize: '20px', padding: '10px 20px', width: 'auto', backgroundColor: 'blue', color: 'white', marginRight: '20px' }}
                >
                  Buy on Raydium
                </Button>
                <Button onClick={() => window.open("https://jup.ag/swap/SOL-GKjnUP1GUXChugXfAb9XbczoQykEX3op9UgywBPHdJGE", "_blank")}  style={{ fontSize: '20px', padding: '10px 20px', width: 'auto', backgroundColor: 'black', color: 'white' }}>
                  Buy on Jupiter
                </Button>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HowToBuy;
