import React, { useState } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const MediaGallery = () => {
  const mediaItems = [
    ...Array(28).fill().map((_, i) => ({
      id: i + 1,
      type: 'image',
      src: `images/burgummeme${i + 1}.jpeg`,
      title: `Image ${i + 1}`
    })),
    ...Array(22).fill().map((_, i) => ({
      id: i + 29,
      type: 'video',
      src: `videos/burgummemevi_${i + 1}.mp4`,
      title: `Video ${i + 1}`
    }))
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllVideos, setShowAllVideos] = useState(false);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  const handleDownload = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFollowLink = () => {
    window.open("https://x.com/Dawgburgum", "_blank");
  };

  // Filter media items by type and manage visibility
  const imageItems = mediaItems.filter(item => item.type === 'image');
  const videoItems = mediaItems.filter(item => item.type === 'video');

  let visibleImageItems = showAllImages ? imageItems : imageItems.slice(0, 9);
  let visibleVideoItems = showAllVideos ? videoItems : videoItems.slice(0, 9);
  // if is mobile, show only 3 items but when show all is clicked, show all and also show them next to each other in a row
  if (window.innerWidth < 600) {
    visibleImageItems = showAllImages ? imageItems : imageItems.slice(0, 3);
    visibleVideoItems = showAllVideos ? videoItems : videoItems.slice(0, 3);
  }

  // if is bigger then laptop show 10 items
  if (window.innerWidth > 1700) {
    visibleImageItems = showAllImages ? imageItems : imageItems.slice(0, 12);
    visibleVideoItems = showAllVideos ? videoItems : videoItems.slice(0, 12);
  }

  return (
<Container fluid style={{ backgroundColor: '#213875', color: 'white', paddingTop: '30px', paddingBottom: '30px' }}>
<div className="text-center mt-0">
        <h1>For more follow us on Twitter</h1>
        <Button variant="primary" onClick={handleFollowLink}>Follow</Button>
      </div>
      <Row className="mt-4">
        <Col md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Images</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center', paddingBottom: '20px' }}>
            {visibleImageItems.map((item) => (
              <Card
                key={item.id}
                onClick={() => handleOpenModal(item)}
                style={{ cursor: 'pointer', width: '200px', height: '200px', overflow: 'hidden' }}
              >
                <Card.Body className="text-center">
                  <img src={item.src} alt={item.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Card.Body>
              </Card>
            ))}
          </div>
          {imageItems.length > 9 && (
              <Button variant="primary" style={{marginBottom:"20px"}} onClick={() => setShowAllImages(!showAllImages)}>
                {showAllImages ? 'Show Less' : 'Show More'}
              </Button>
            )}
        </Col>
        <Col md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Videos</h2>
<div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center', paddingBottom: '20px' }}>
            {visibleVideoItems.map((item) => (
              <Card
                key={item.id}
                onClick={() => handleOpenModal(item)}
                style={{ cursor: 'pointer', width: '200px', height: '200px', overflow: 'hidden' }}
              >
                <Card.Body className="text-center">
                <video width="100%" height="100%" controls style={{ objectFit: 'contain' }} preload="metadata">
                <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Card.Body>
              </Card>
            ))}
          </div>

          {videoItems.length > 9 && (
              <Button variant="primary" onClick={() => setShowAllVideos(!showAllVideos)}>
                {showAllVideos ? 'Show Less' : 'Show More'}
              </Button>
            )}
        </Col>
        
      </Row>
      {selectedItem && (
       <Modal
       isOpen={modalIsOpen}
       onRequestClose={handleCloseModal}
       contentLabel="Media Modal"
       className="fullscreen-modal"
       overlayClassName="modal-overlay"
       style={{
         content: {
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           width: '90%', // More screen real estate on mobile
           height: '90%', // Taller modal to use more vertical space
           overflow: 'auto', // Enable scrolling within the modal
         },
         overlay: {
           backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dim the background more for focus
         },
       }}
     >
       <div style={{ position: 'relative', width: '100%', height: '100%' }}>
         <Card style={{ height: '100%', width: '100%' }}>
           <Card.Body className="text-center" style={{ padding: '0', height: 'calc(100% - 56px)' }}>
             {selectedItem.type === 'image' ? (
               <img src={selectedItem.src} alt={selectedItem.title} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
             ) : (
               <video width="100%" height="100%" controls style={{ objectFit: 'contain' }}>
                 <source src={selectedItem.src} type="video/mp4" />
                 Your browser does not support the video tag.
               </video>
             )}
             <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
               <Button
                 variant="primary"
                 className="mr-2"
                 onClick={() => handleDownload(selectedItem.src)}
               >
                 Download
               </Button>
               <Button
                 variant="primary"
                 style={{ backgroundColor: 'red', marginLeft: '10px' }}
                 onClick={handleCloseModal}
               >
                 Close
               </Button>
             </div>
           </Card.Body>
         </Card>
       </div>
     </Modal>
     
      )}
    </Container>
  );
};

export default MediaGallery;
