import React, { useState } from 'react';
import { Navbar, Container, Nav, Offcanvas, Modal, Button } from 'react-bootstrap';
import { MemoryGame } from "../MemoryGame";

const CustomNavbar = () => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowModal(false);
  };
  const handleShow = () => setShow(true);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Navbar variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <Nav.Link href="#" onClick={() => window.open("https://dawgburgum.com/", "_blank")}>
              <span id="logo"><b>$BURGUM</b></span>
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="ml-auto text-center">
              <Nav.Link
                href="#"
                onClick={handleShowModal}
              >
                <h3>BUY</h3>
              </Nav.Link>
              <Nav.Link href="/memes"><h3>MEMES</h3></Nav.Link>
              <Nav.Link href="#" onClick={() => window.open("https://t.me/dawgburgum", "_blank")}><h3>TELEGRAM</h3></Nav.Link>
              <Nav.Link href="#" onClick={() => window.open("https://x.com/Dawgburgum", "_blank")}><h3>TWITTER</h3></Nav.Link>
              <Nav.Link href="#" onClick={() => window.open("https://dexscreener.com/solana/A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC", "_blank")}><h3>CHART</h3></Nav.Link>
              <Nav.Link href="#" onClick={handleShow}><h3>GAME</h3></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Memory Game</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <MemoryGame />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy $BURGUM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            onClick={() => window.open("https://raydium.io/swap/?inputCurrency=sol&outputMint=A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC&fixed=in&inputMint=sol", "_blank")}
            className="custom-button"
            style={{ fontSize: '20px', padding: '10px 20px', width: 'auto' }}
          >
            Buy on Raydium
          </Button>
          <Button
            onClick={() => window.open("https://jup.ag/swap/SOL-GKjnUP1GUXChugXfAb9XbczoQykEX3op9UgywBPHdJGE", "_blank")}
            className="custom-button"
            style={{ fontSize: '20px', padding: '10px 20px', width: 'auto', backgroundColor: 'green', color: 'white' }}
          >
            Buy on Jupiter
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomNavbar;
