import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Asset2 from "./assets/memes/2.jpg";
import flipSound from "./assets/bark.mp3";

const images = [
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
  Asset2,
];

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function MemoryGame() {
  const [tiles, setTiles] = useState([]);
  const [flippedIndices, setFlippedIndices] = useState([]);
  const [matchedIndices, setMatchedIndices] = useState([]);
  const audioRef = useRef(new Audio(flipSound)); // Using React ref to handle audio

  const [showEmoji, setShowEmoji] = useState({});

  useEffect(() => {
    const shuffledImages = [...images];
    shuffleArray(shuffledImages);
    setTiles(shuffledImages);
  }, []);

  const handleTileClick = (index) => {
    if (flippedIndices.includes(index) || matchedIndices.includes(index)) {
      return;
    }

    audioRef.current.play();

    const newFlippedIndices = [...flippedIndices, index];
    setFlippedIndices(newFlippedIndices);

    if (newFlippedIndices.length === 2) {
      const match = tiles[newFlippedIndices[0]] === tiles[newFlippedIndices[1]];
      if (match) {
        setMatchedIndices(prev => [...prev, ...newFlippedIndices]);
        setFlippedIndices([]);
      } else {
        setTimeout(() => {
          setFlippedIndices([]);
        }, 1000);
      }
    }
  };

  return (
    <Container className="d-flex justify-content-center row mb-5 px-5">
            <h1 className="text-center mb-5">
        Who let the DAWG out?
      </h1>
      <Row style={{ width: 600 }}>
        {tiles.map((tile, index) => (
          <Col xs={3} md={2} key={index} className="p-1">
            <div
              style={{
                width: "100%",
                height: 0,
                paddingBottom: "100%", // Makes the div square
                cursor: "pointer",
                backgroundImage: `url(${flippedIndices.includes(index) || matchedIndices.includes(index) ? tile : 'transparent'})`,
                backgroundSize: "cover",
                border: "0px solid black",
                backgroundColor: "#fff", // Changed from "#ccc" to white background
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                borderRadius: 10,
                backgroundColor: "#da1233"
              }}
              onClick={() => handleTileClick(index)}
            >
              {!flippedIndices.includes(index) && !matchedIndices.includes(index) && "💰"}
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}