import React from 'react';
import MediaGallery from "../components/mediaGallery";
import CustomNavbar from "../components/customNavbar";

function Memes() {
  return (
    <div>
        <CustomNavbar />
      <MediaGallery />
    </div>
  );
}

export default Memes;