import React from 'react';
import { Container } from 'react-bootstrap';
import Asset12 from '../assets/memes/12.png';
import Asset13 from '../assets/memes/13.png';

const InteractiveBackground = ({ showAlternate, setShowAlternate, isMobile }) => {
  return (
    <Container
      className="text-center mt-0 mb-0"
      fluid
      style={{
        padding: 0,
        backgroundImage:
          "url(https://d7hftxdivxxvm.cloudfront.net/?height=2000&quality=80&resize_to=fit&src=https%3A%2F%2Fartsy-media-uploads.s3.amazonaws.com%2F2RNK1P0BYVrSCZEy_Sd1Ew%252F3417757448_4a6bdf36ce_o.jpg&width=2000)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <img
        src={showAlternate ? Asset13 : Asset12}
        alt="Clickable"
        fluid
        onMouseDown={() => setShowAlternate(true)}
        onMouseUp={() => setShowAlternate(false)}
        onMouseLeave={() => setShowAlternate(false)}
        onTouchStart={() => setShowAlternate(true)}
        onTouchEnd={() => setShowAlternate(false)}
        style={{
          cursor: "crosshair",
          width: isMobile ? "100%" : "40%",
          height: "auto",
        }}
      />
    </Container>
  );
};

export default InteractiveBackground;
