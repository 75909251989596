import React, { useState } from "react";
import { Row, Col, Container, } from "react-bootstrap";

import Countdown from "../components/countDown";
import ChartEmbed from "../components/chartEmbed";
import MemeCarousel from "../components/memeCarousel";
import CustomNavbar from "../components/customNavbar";
import DawgBurgumSection from "../components/dawgBurgumSection";
import DawgBurgumBanner from "../components/dawgBurgumBanner";
import SlideBanner from "../components/slideBanner";
import VideoBanner from "../components/videoBanner";
import InteractiveBackground from "../components/interactiveBackground";
import ContractAddressCard from "../components/contractAdressCard";
import { MemoryGame } from "../MemoryGame";

import Dawg0 from "../assets/memes/dawg_0.jpg";
import Asset7 from "../assets/memes/7.png";
import HowToBuy from "../components/howToBuy";
import MemeBanner from "../components/memeBanner";
import MediaGallery from "../components/mediaGallery";


function Home() {
  var isMobile = window.innerWidth < 600;
  const [showAlternate, setShowAlternate] = useState(false);

  return (
    <>
          {/* <VideoBanner videoSource="../assets/video/banner.mp4" /> */}
      <CustomNavbar />
      <DawgBurgumSection />
      <SlideBanner />
      <DawgBurgumBanner />
    <HowToBuy />
    <MemeBanner 
        videoSrcMp4="../videos/burgumbanner.mp4"
        videoSrcWebm="../videos/burgumbanner.webm"
        title="Check out our memes"
      />
            <InteractiveBackground
      showAlternate={showAlternate}
      setShowAlternate={setShowAlternate}
    />
    <MediaGallery />
{/* 
      <Container fluid style={{ padding: 0 }}>
        <div className="banner-container">
          <img src={Dawg0} alt="Banner" />
        </div>
      </Container>

      <Container className="text-center mt-5">
        <Countdown />
        <ChartEmbed />
      </Container> */}
{/* 
      <DawgBurgumSection />
      <SlideBanner />
      <InteractiveBackground />
      <ContractAddressCard /> */}

      {/* <Container
        fluid
        style={{
          width: "100%",
          padding: 0,
          margin: 0,
          backgroundColor: "#213875",
        }}
      >
        <Row
          className="text-center"
          style={{ width: "100%", margin: 0, fontSize: 60 }}
        >
          <b
            style={{
              backgroundColor: "#da1233",
              color: "white",
              marginBottom: 40,
            }}
          >
            WHO LET THE $DAWG OUT?
          </b>
          <Col xs={12} style={{ padding: 0 }}>
            <MemoryGame />
          </Col>
        </Row>
      </Container> */}

      {/* <Container
        fluid
        style={{ width: "100%", padding: 0, margin: "30px 0px" }}
      >
        <Row style={{ width: "100%", margin: 0 }}>
          <Col
            xs={12}
            style={{
              padding: 0,
              backgroundImage:
                "url(https://i.etsystatic.com/10379022/r/il/0f0087/1965483172/il_1140xN.1965483172_h7m3.jpg)",
              backgroundSize: "100%",
              backgroundRepeat: "repeat",
            }}
          >
            <img
              src={Asset7}
              alt="Full Width"
              style={{ objectFit: "fill", width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container> */}

      {/* <MemeCarousel /> */}
    </>
  );
}

export default Home;
