import React from 'react';
import { Button } from 'react-bootstrap';

const MemeBanner = ({ videoSrcMp4, videoSrcWebm, title }) => {
    const handleMemeNav = () => {
        window.open("/memes", "_blank");
     };
  return (
    <div className="meme-banner-container">
      <video className="meme-banner-video" autoPlay loop muted>
        <source src={videoSrcMp4} type="video/mp4" />
        <source src={videoSrcWebm} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="meme-banner-content">
        <h1 className="meme-banner-title">{title}</h1>
        <Button className="meme-banner-button" variant="primary" onClick={handleMemeNav}>Memes</Button>
      </div>
    </div>
  );
};

export default MemeBanner;
