import React from 'react';
import PropTypes from 'prop-types';

const VideoBanner = ({ videoSource }) => {
  // Check if videoSource is a valid string
  const source = videoSource && typeof videoSource === 'string' ? videoSource : '/src/assets/video/banner.mp4';
    console.log("source", source);
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <video autoPlay muted loop style={{ width: '100%' }}>
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

VideoBanner.propTypes = {
  videoSource: PropTypes.string,
};

VideoBanner.defaultProps = {
  videoSource: '/src/assets/video/banner.mp4',
};

export default VideoBanner;
