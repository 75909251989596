import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Countdown from './countDown';

const DawgBurgumBanner = () => {
  return (
    <Container fluid className="mt-0 dawg-burgum-banner">
      <Row className="align-items-center">
        <Col xs={12} md={6} lg={5}>
          <img
            src="/images/burgumspeaking.jpeg"
            alt="Dawg Burgum"
            className="img-fluid rounded shadow"
          />
        </Col>
        <Col xs={12} md={6} lg={7} className="text-section">
          <h2 className="mb-3 mt-3">I am Dawg Burgum,</h2>
          <h3 className="mb-3">the future Vice President of the United States of America,</h3>
          <p className="mb-4">Together with President Doland Tremp, we will Make Memes Great Again.</p>
          <p className="mb-4">Don't forget, the elections are on November 5th.</p>
          <Countdown />
        </Col>
      </Row>
    </Container>
  );
}

export default DawgBurgumBanner;
