import React from 'react';
import './index.css';
import Home from './pages/Home';
import Memes from './pages/Memes';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// Create a root and render your application
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/memes" element={<Memes />}/>
    </Routes>
  </BrowserRouter>
);
