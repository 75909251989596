import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { FaTelegram, FaTiktok } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";

const DawgBurgumSection = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <Container fluid className="image-container">
      <Row className="h-100 custom-row">
        <Col md={6} className="info-section">
          <h1 className="title">Dawg Burgum</h1>
          <p className="description">
            Join me, Dawg Burgum, as your future Vice President of the United States.
            Alongside President Doland Tremp, our mission is to Make Memes Great Again!
          </p>
          <div className="button-group" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <Button
              onClick={handleShow}
              className="custom-button"
              style={{ fontSize: '20px', padding: '20px 40px', width: 'auto', backgroundColor: 'blue', color: 'white', marginBottom: '10px' }}
            >
              Buy
            </Button>
            <Button
              onClick={() => window.open("https://x.com/intent/tweet?text=I support $BURGUM @dawgburgum", "_blank")}
              className="custom-button"
              style={{ fontSize: '20px', padding: '20px 40px', width: 'auto', backgroundColor: 'red', color: 'white' }}
            >
              Support $BURGUM
            </Button>
          </div>
          <Row className="social-media-icons">
        <Col className="text-center py-3">
        <a href="https://x.com/Dawgburgum" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
  <FaSquareXTwitter className="social-icon" size="30" style={{ margin: '0 10px' }} />
</a>
<a href="https://tiktok.com/@Dawgburgum" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
  <FaTiktok className="social-icon" size="30" style={{ margin: '0 10px' }} />
</a>
<a href="https://t.me/Dawgburgum" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
  <FaTelegram className="social-icon" size="30" style={{ margin: '0 10px' }} />
</a>
        </Col>
      </Row>
        </Col>
        <Col md={6} className="image-section">
          <img
            src="/images/burgum.jpeg"
            alt="Dawg Burgum"
            className="portrait"
          />
        </Col>
      </Row>
      
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy $BURGUM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            onClick={() => window.open("https://raydium.io/swap/?inputCurrency=sol&outputMint=A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC&fixed=in&inputMint=sol", "_blank")}
            className="custom-button"
            style={{ fontSize: '20px', padding: '10px 20px', width: 'auto' }}
          >
          Buy on Raydium
          </Button>
          <Button
            onClick={() => window.open("https://jup.ag/swap/SOL-GKjnUP1GUXChugXfAb9XbczoQykEX3op9UgywBPHdJGE", "_blank")}
            className="custom-button"
            style={{ fontSize: '20px', padding: '10px 20px', width: 'auto', backgroundColor: 'green', color: 'white' }}
          >
            Buy on Jupiter
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default DawgBurgumSection;
